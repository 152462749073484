<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="openDialog()">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Taxi
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Taxis"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="taxis"
          :search.sync="search"
          :options.sync="options"
          :server-items-length="totalTaxis"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="750px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="TaxiForm">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.vehicleNo"
                            label="Vehicle Number"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.vehicleTypeId"
                            :items="vehicleTypes"
                            item-text="name"
                            item-value="vehicleTypeId"
                            label="Vehicle Type"
                            :rules="[rules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.model"
                            label="Vehicle Model"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.maxPassengers"
                            label="Seating Capacity"
                          />
                        </v-col>
                        <v-col cols="12" md="6" v-if="editedIndex > -1">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="Owner first name"
                          />
                        </v-col>
                        <v-col cols="12" md="6" v-if="editedIndex > -1">
                          <v-text-field
                            v-model="editedItem.middleName"
                            label="Owner middle name"
                          />
                        </v-col>
                        <v-col cols="12" md="6" v-if="editedIndex > -1">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Owner last name"
                          />
                        </v-col>
                        <v-col cols="12" md="6" v-if="editedIndex > -1">
                          <v-text-field
                            v-model="editedItem.mobile"
                            label="Owner phone number"
                          />
                        </v-col>
                        <v-col cols="12" md="6" v-if="editedIndex > -1">
                          <v-text-field
                            v-model="editedItem.telephone"
                            label="Owner telephone"
                          />
                        </v-col>
                        <v-col cols="12" v-if="editedIndex > -1">
                          <div><label>Owner Date of Birth</label></div>
                          <v-date-picker
                            v-model="editedItem.dateOfBirth"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>Rego expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.regoExpiry"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>Insurance expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.insuranceExpiry"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>CTP expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.ctpExpiry"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>Taxi license expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.taxiLicenseExpiry"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>Inspection expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.inspectionExpiry"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>Camera alarm expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.cameraAlarmExpiry"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>Alarm expiry</label></div>
                          <v-date-picker
                            v-model="editedItem.alarmExpiry"
                          ></v-date-picker>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.ownerName`]="{ item }">
            <div v-if="item.owner">
              {{
                (item.owner.firstName ? item.owner.firstName + " " : "") +
                (item.owner.middleName ? item.owner.middleName + " " : "") +
                (item.owner.lastName ? item.owner.lastName : "")
              }}
            </div>
          </template>
          <template v-slot:[`item.alarmExpiry`]="{ item }">
            <div>{{ formatDate(item.alarmExpiry) }}</div>
          </template>
          <template v-slot:[`item.cameraAlarmExpiry`]="{ item }">
            <div>{{ formatDate(item.cameraAlarmExpiry) }}</div>
          </template>
          <template v-slot:[`item.inspectionExpiry`]="{ item }">
            <div>{{ formatDate(item.inspectionExpiry) }}</div>
          </template>
          <template v-slot:[`item.taxiLicenseExpiry`]="{ item }">
            <div>{{ formatDate(item.taxiLicenseExpiry) }}</div>
          </template>
          <template v-slot:[`item.ctpExpiry`]="{ item }">
            <div>{{ formatDate(item.ctpExpiry) }}</div>
          </template>
          <template v-slot:[`item.insuranceExpiry`]="{ item }">
            <div>{{ formatDate(item.insuranceExpiry) }}</div>
          </template>
          <template v-slot:[`item.regoExpiry`]="{ item }">
            <div>{{ formatDate(item.regoExpiry) }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Vehicle No",
        align: "start",
        value: "vehicleNo",
      },
      { text: "Type", value: "vehicleTypeId" },
      { text: "Model", value: "model" },
      { text: "Seating Capacity", value: "maxPassengers" },
      { text: "Owner", value: "ownerName" },
      { text: "Phone", value: "owner.mobile" },
      { text: "Telephone", value: "owner.telephone" },
      { text: "DOB", value: "owner.dateOfBirth" },
      { text: "Rego Expiry", value: "regoExpiry" },
      { text: "Insurance Expiry", value: "insuranceExpiry" },
      { text: "CTP Expiry", value: "ctpExpiry" },
      { text: "Taxi Lisense Expiry", value: "taxiLicenseExpiry" },
      { text: "Inspection Expiry", value: "inspectionExpiry" },
      { text: "Camera Alarm Expiry", value: "cameraAlarmExpiry" },
      { text: "Alarm Expiry", value: "alarmExpiry" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    taxis: [],
    vehicleTypes: [],
    options: {},
    totalTaxis: 0,
    loading: false,
    editedIndex: -1,
    editedItem: {
      vehicleNo: "",
      maxPassengers: "",
      model: "",
      regoExpiry: "",
      insuranceExpiry: "",
      ctpExpiry: "",
      dateOfBirth: moment().format("YYYY-MM-DD"),
      taxiLicenseExpiry: "",
      cameraAlarmExpiry: "",
      alarmExpiry: "",
      inspectionExpiry: "",
      vehicleTypeId: "",
    },
    defaultItem: {
      vehicleNo: "",
      maxPassengers: "",
      model: "",
      regoExpiry: "",
      dateOfBirth: moment().format("YYYY-MM-DD"),
      insuranceExpiry: "",
      ctpExpiry: "",
      taxiLicenseExpiry: "",
      cameraAlarmExpiry: "",
      alarmExpiry: "",
      inspectionExpiry: "",
      vehicleTypeId: "",
    },
    search: "",
    searchTimerId: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Taxi" : "Edit Taxi";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getAllTaxis("");
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(() => {
          this.getAllTaxis(this.search);
        }, 2000);
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("/VehicleType")
        .then((resposne) => {
          this.vehicleTypes = resposne.data;
        })
        .catch(() => {});
    },
    getAllTaxis(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        skip: itemsPerPage * (page - 1),
        take: itemsPerPage,
        searchColumn: "",
        searchValue: search,
      };

      this.$http
        .post("/Vehicle/GetAllBySearch", data)
        .then((resposne) => {
          this.loading = false;
          this.totalTaxis = resposne.data.total;
          this.taxis = resposne.data.vehicles;
        })
        .catch(() => {});
    },
    openDialog() {
      this.editedItem.regoExpiry = moment().format("YYYY-MM-DD");
      this.editedItem.insuranceExpiry = moment().format("YYYY-MM-DD");
      this.editedItem.ctpExpiry = moment().format("YYYY-MM-DD");
      this.editedItem.taxiLicenseExpiry = moment().format("YYYY-MM-DD");
      this.editedItem.cameraAlarmExpiry = moment().format("YYYY-MM-DD");
      this.editedItem.alarmExpiry = moment().format("YYYY-MM-DD");
      this.editedItem.inspectionExpiry = moment().format("YYYY-MM-DD");
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.taxis.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.firstName =
        item.owner && item.owner.firstName ? item.owner.firstName : "";
      this.editedItem.middleName =
        item.owner && item.owner.middleName ? item.owner.middleName : "";
      this.editedItem.lastName =
        item.owner && item.owner.lastName ? item.owner.lastName : "";
      this.editedItem.mobile =
        item.owner && item.owner.mobile ? item.owner.mobile : "";
      this.editedItem.telephone =
        item.owner && item.owner.telephone ? item.owner.telephone : "";

      this.editedItem.regoExpiry = moment
        .utc(item.regoExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.editedItem.insuranceExpiry = moment
        .utc(item.insuranceExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.editedItem.ctpExpiry = moment
        .utc(item.ctpExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.editedItem.taxiLicenseExpiry = moment
        .utc(item.taxiLicenseExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.editedItem.cameraAlarmExpiry = moment
        .utc(item.cameraAlarmExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.editedItem.alarmExpiry = moment
        .utc(item.alarmExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.editedItem.inspectionExpiry = moment
        .utc(item.inspectionExpiry)
        .local()
        .format("YYYY-MM-DD");
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.taxis.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/Vehicle/${this.editedItem.id}`)
        .then((response) => {
          if (response.data.success) {
            this.taxis.splice(index, 1);
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch(() => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.TaxiForm.validate()) {
        if (this.editedIndex > -1) {
          if (this.editedItem.owner) {
            this.editedItem.ownerId = this.editedItem.owner.id;
          }
          let index = this.editedIndex;
          this.$http
            .put(`/Vehicle/${this.editedItem.id}`, this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.taxis.splice(index, 1, response.data.data);
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        } else {
          this.editedItem.maxPassengers = this.editedItem.maxPassengers || 0;
          this.editedItem.vehicleTypeId = this.editedItem.vehicleTypeId || 0;
          this.$http
            .post("/Vehicle", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.taxis.unshift(response.data.data);
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        }
        this.close();
      }
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY");
      } else {
        return "";
      }
    },
  },
};
</script>
